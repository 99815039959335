<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form v-if="!readType" class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right"
      @submit="table.save">
      <vxe-form-item title="工單號碼" field="JobTickeId" :item-render="{}" span="6">
        <template #default="{ data }">
          <select-box v-if="data" v-bind="jobTickeSelectorOptions" :focusRefresh="true" @change="jobTickeProduct(data)"
            v-model="data.WorkOrder" />
        </template>
      </vxe-form-item>
      <vxe-form-item title="工單品項" field="JobTickeItemNo" :item-render="{}" @change="selectJobTickeItem(data)" span="6">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.JobTickeItemNo" placeholder="請輸入品項序號" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="光益品號" field="ProductId" :item-render="{}" span="24">
        <template #default="{ data }">
          <select-box v-bind="selectorProductOptions" :focusRefresh="true" @change="customerProduct(data)"
            v-model="data.ProductId" />
        </template>
      </vxe-form-item>
      <template v-if="$props.DepartmentId !== '內銷'">
        <vxe-form-item title="用途" field="Purpose" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Purpose" placeholder="用途" clearable>
              <vxe-option v-for="item in purposeContent" :key="item.value" :value="item.value"
                :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
      </template>
      <template v-if="$props.DepartmentId !== '內銷'">
        <vxe-form-item title="尺寸" field="ProductSize" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.ProductSize" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
      </template>
      <template v-if="$props.DepartmentId !== '內銷'">
        <vxe-form-item title="外觀" field="Exterior" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Exterior" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
      </template>
      <template v-if="$props.DepartmentId !== '內銷'">
        <vxe-form-item title="性能" field="Performance" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Performance" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
      </template>
      <vxe-form-item title="品名" field="Name" :item-render="{}" span="6">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.Name" placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="規格" field="Specification" :item-render="{}" span="6">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.Specification" placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="顏色" field="Color" :item-render="{}" span="6">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.Color" placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="管芯" field="PaperTube" :item-render="{}" span="6">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.PaperTube" placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="標頭紙" field="TitlePaper" :item-render="{}" span="6">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.TitlePaper" placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="包裝方式" field="Package" :item-render="{}" span="6">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.Package" placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="膠性" field="Viscosity" :item-render="{}" span="6">
        <template #default="{ data }">
          <vxe-select v-model="data.Viscosity" class="border rounded">
            <vxe-option v-for="item in viscosityType" :key="item.value" :value="item.value"
              :label="`${item.label}`"></vxe-option>
          </vxe-select>
          <!-- <vxe-input type="text" v-model="data.Viscosity" placeholder="請輸入文字" clearable></vxe-input> -->
        </template>
      </vxe-form-item>
      <vxe-form-item title="供應商" field="Supplier" :item-render="{}" span="6">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.Supplier" placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="數量" field="Quantity" :item-render="{}" span="6">
        <template #default="{ data }">
          <vxe-input type="number" v-model="data.Quantity" placeholder="請輸入數量" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="完成日期" field="CompletionDate" :item-render="{}" span="6">
        <template #default="{ data }">
          <vxe-input type="date" v-model="data.CompletionDate" placeholder="請輸入日期" label-format="yyyy/MM/dd"
            clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="備註" field="Remark" :item-render="{}" span="24">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.Remark" placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item :item-render="{}" span="3">
        <template #default>
          <vxe-button type="submit" status="primary" :content="table.isEditing ? '修改' : '確認'">
          </vxe-button>
          <vxe-button v-if="table.isEditing" type="button" @click="table.reset" status="danger" content="取消">
          </vxe-button>
        </template>
      </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="ItemNo" title="項次" sortable width="80">
      </vxe-column>
      <vxe-column field="Name" title="品名" sortable>
      </vxe-column>
      <vxe-column field="Specification" title="規格" sortable>
      </vxe-column>
      <vxe-column field="Color" title="顏色" sortable>
      </vxe-column>
      <vxe-column field="PaperTube" title="管芯" sortable>
      </vxe-column>
      <vxe-column field="TitlePaper" title="標頭紙" sortable>
      </vxe-column>
      <vxe-column field="Package" title="包裝方式" sortable>
      </vxe-column>
      <vxe-column field="Viscosity" title="膠性" sortable width="80">
      </vxe-column>
      <vxe-column field="Quantity" title="數量" sortable width="80">
      </vxe-column>
      <vxe-column field="Supplier" title="供應商" sortable>
      </vxe-column>
      <vxe-column field="Remark" title="備註" sortable>
      </vxe-column>
      <vxe-column field="Purpose" title="用途" sortable>
      </vxe-column>
      <vxe-column field="Exterior" title="外觀" sortable>
      </vxe-column>
      <vxe-column field="ProductSize" title="尺寸" sortable>
      </vxe-column>
      <vxe-column field="Performance" title="性能" sortable>
      </vxe-column>
      <vxe-column v-if="!readType" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString';

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    DepartmentId: {
      type: String,
      default: ""
    },
    aid: Number,
    contentType: Number,
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const jobId = ref(0);
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {} as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
        if (table.formData.CompletionDate == '0001-01-01T00:00:00') {
          table.formData.CompletionDate = new Date();
        }
      },
      delete(row: any) {
        grid.value.remove(row);
        emit('updateTotal');
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {} as any;
        table.formData.Quantity = 1;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        if (table.isEditing) {
          // 編輯確認
          if(table.formData.Purpose != null && table.formData.ProductSize == null && table.formData.Exterior == null && table.formData.Performance == null){
            alert('尺寸、外觀、功能至少要輸入一項');
            return false;
          }
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          if(table.formData.Purpose != null && table.formData.ProductSize == null && table.formData.Exterior == null && table.formData.Performance == null){
            alert('尺寸、外觀、功能至少要輸入一項');
            return false;
          }
          //console.log('table.selectedR', table.selectedRow);
          // console.log('submit table', table);
          table.formData.ItemNo = getNextOrdinal();
          await grid.value.insert(table.formData);
        }
        emit('updateTotal');
        table.reset();
      },
      rules: {
        Number: [{ required: true }],
        Name: [{ required: true }],
        AcquireDate: [{ required: true }],
        SendDate: [{ required: true }],
        Purpose:[{ required: true }],
        // ProductSize:[{ required: true }],
        // Exterior:[{ required: true }],
        // Performance:[{ required: true }],
      }
    })

    watch(() => props.aid, async (value) => {
      if (value) {
        console.log(props.contentType)
        if (props.data && props.data.length > 0) {
          table.data = props.data;
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorProductOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "ProductName",
      valueField: "Id",
      columns: [
        {
          field: "ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "SKU",
          title: "SKU",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductSize",
          title: "規格",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value),
        query: params => {
          return model!.dispatch("product/query", params)
        }
      }
    };

    const customerProduct = async (data: any) => {
      if (data?.ProductId) {
        // console.log(data.ProductId);
        try {
          const temp = await model?.dispatch("product/find", data.ProductId);
          if (temp) {
            // console.log(temp);
            data.Name = temp.ProductName;
            data.Specification = temp.ProductSize;
            data.Color = temp.Color;
            data.PaperTube = temp.PaperTube;
            data.TitlePaper = temp.TitlePaper;
            data.Package = temp.Package;
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value),
        query: params => {
          return model!.dispatch("position/query", params)
        }
      }
    };
    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };
    const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const viscosityType = [
      { label: "水膠", value: "水膠" },
      { label: "熱熔膠", value: "熱熔膠" },
      { label: "油膠", value: "油膠" },
      { label: "壓克力膠", value: "壓克力膠" },
      { label: "橡膠", value: "橡膠" },
    ]

    const jobTickeProduct = async (data: any) => {
      if (data?.WorkOrder) {
        try {
          data.jobticketId = data.WorkOrder;
          jobId.value = data.WorkOrder;
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const selectJobTickeItem = async (data: any) => {
      if (data?.WorkOrder) {
        try {
          data.jobticketId = data.WorkOrder;
          jobId.value = data.WorkOrder;
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const colorBoxCategory = [
      { label: "包裝盒", value: "包裝盒" },
      { label: "車型盒", value: "車型盒" },
      { label: "切台盒", value: "切台盒" },
      { label: "內盒", value: "內盒" },
      { label: "托盒", value: "托盒" },
      { label: "吊盒", value: "吊盒" },
    ]

    const cartonCategory = [
      { label: "外箱", value: "外箱" },
      { label: "內箱", value: "內箱" },
      { label: "其他", value: "其他" },
    ]

    const getNextOrdinal = () => {
      const data = grid.value.getTableData().fullData;
      if (!data || data.length == 0) return 1;
      var maxOrdinal = Math.max(...data.map((e: any) => e.ItemNo));
      return (maxOrdinal ?? 0) + 1;
    }

    const purposeContent = [
      { label: "大貨樣品", value: "大貨樣品" },
      { label: "檢驗樣品", value: "檢驗樣品" },
      { label: "SGS檢驗樣品", value: "SGS檢驗樣品" },
      { label: "客戶檢驗樣品", value: "客戶檢驗樣品" },
      { label: "客戶展示品", value: "客戶展示品" },
    ]

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      TF,
      colorBoxCategory,
      cartonCategory,
      formatDate,
      customerProduct,
      selectorProductOptions,
      jobTickeSelectorOptions,
      jobTickeProduct,
      selectJobTickeItem,
      viscosityType,
      purposeContent
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
