
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    aid: Number,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,      
      submit() {
      },
      async save() {        
      },
      rules: {
        Name: [{ required: true }],
        Comment: [{ required: false }],
      }
    })

    watch(() => props.aid, async(value) => {
      if(value) {
        table.formData.Name = CloudFun?.user?.Name ?? "";
        table.formData.Approved = false;
        table.formData.Comment = '';
      }
    }, { immediate: true });

    const signForm = ref<any>();

    return {
      table,
      grid,
      gridOptions,
      signForm,
      model,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    },
    async submitData() {
      var result = null
      await this.signForm.validate((errMap: any) => { 
        if (errMap === undefined) {
          result = this.table.formData;
        }
      })
      return result;
    },
  }
})
